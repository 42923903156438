<template>
	<div id="main-wrapper" class="inner-pages forex-page mt4-page">
		<div class="section banner-holder">
			<div class="forex-nav">
				<div class="wrapper">
					<ul>
						<li> <router-link to="/mt4">MT4</router-link> </li>
						<!-- <li> <router-link to="/mt5">MT5</router-link> </li> -->
						<!-- <li> <router-link to="/act-trader">ActTrader</router-link> </li> -->
						<li class="active"> <router-link to="/zulu">ZuluTrade</router-link> </li>
					</ul>
				</div>
			</div>

			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>ZuluTrade</h2>
				<div class="img-holder">
					<img src="assets/images/zulu-img1.webp" alt="AAAFx" title="AAAFx" width="745" height="404" class="fluid-img" />
				</div>
			</div>
		</div>
		<div class="section content-mt4 content-zulu t-left">
			<div class="wrapper">
				<div class="title-holder">
					<h5>People tend to perform better in communities and by following those who can be relied on.</h5>
					<br>
					<p>AAAfx understands this need and thereby offers the most sought after Zulu Trade Platform. Zulu Trade was founded in 2007, at a time when the world lacked a suitable mechanism to audit trading practices and for sharing its trading knowledge with one another. This platform also eliminated the need to sit hours in front of a screen and introduced automated and professional financial trading to the mainstream.
					<br><br>
					Zulu Trade is an automated trading platform, which provides a thorough statistical analysis of the top traders in order to allow a user to gain insight and establish a system of copying trades and earning the advantage

					</p>
				</div>
				<div class="listing t-center">
					<div class="title-holder">
							<h5>Start trading in just 3 simple steps:</h5>
					</div>
					<div class="step-zulu wow fadeIn"> 
						<img src="assets/images/step1.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						<p>Open and Fund your AAAFx <br>
							ECN Zulu Trade Account</p>
					</div>
					<div class="step-zulu wow fadeIn" data-wow-delay="0.3s">
						<img src="assets/images/step2.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						<p>Analyse the performance of various traders. </p>
					</div>
					<div class="step-zulu wow fadeIn" data-wow-delay="0.6s">
						<img src="assets/images/step3.webp" alt="AAAFx" title="AAAFx" width="42" height="42" class="fluid-img" />
						<p>Select the trader you want to copy.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section forex-content">
			<div class="wrapper2">
				<!-- <div class="section equi-what">
					<div class="wrapper">
						<div class="mockup-holder wow fadeIn">
							<img src="assets/images/mockup-holder.webp" alt="AAAFx" title="AAAFx" width="864" height="511" class="fluid-img" />
							<div class="video-holder">
								---previously-commented<video src="https://media.geeksforgeeks.org/wp-content/uploads/20210314115545/sample-video.mp4" width="100%" height="100%" controls=""> The browser does not support videos. </video>
								<iframe style="width: 100%; height: 100%;" src="https://www.youtube.com/embed/yz4qeidVkvw?autoplay=1&loop=1&autopause=0&muted=1" title="YouTube video player" frameborder="0" allow="autoplay;" allowfullscreen></iframe>
							</div>
						</div>
					</div>

				</div> -->
				<div class="section">
					<div class="content-sec mt4-phone zulu-web">
						<div class="img-holder wow fadeInLeft">
							<img src="assets/images/zulu-img3.webp" alt="AAAFx" title="AAAFx" width="600" height="388" class="fluid-img" />
						</div>
						<div class="content-holder wow fadeInRight">
							<img src="assets/images/zulu-ico1.webp" alt="AAAFx" title="AAAFx" width="418" height="128" class="fluid-img" />
							<br><br>
							<p>You can also choose from diversified strategies built from the best combinations of the best traders on Zulu Trade.
							Choose the Combo+ service and get your account managed by ZuluTrade’s Certified Management Portfolio Team on fully automatic or semi automatic mode, as you wish!
							<br><br>
							ZTP is a pure web-based platform which can easily be accessed here</p>
							<div class="btn-holder wow fadeIn">
								<a href="#" class="btn btn-grey"> <img src="assets/images/desk-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="49" class="fluid-img" /> Web based Platform</a>
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>
		<div class="section content-mt4">
			<div class="wrapper">
				<div class="title-holder">
					<h3>AAAFx provides exceptional trading experience combined with the world class ZTP’s following features:</h3>
					<br>
				</div>
				<div class="img-holder t-center">
					<img src="assets/images/zulu-img4.webp" alt="AAAFx" width="1128" height="288" class="fluid-img" />
				</div>
			</div>
		</div>

	</div>
</template>